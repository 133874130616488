/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

////////////////////////////////////////////////////////////////////////////
////                                                                    ////
////             THIS FILE HAS BEEN GENERATED AUTOMAGICALLY             ////
////                                                                    ////
////////////////////////////////////////////////////////////////////////////

export const Config = {
    "branch": "main",
    "version": "v1.31.0.dev.374",
    "name": "stage",
    "mailDomain": "mbeta.info",
    "mailPrefix": "payer_",
    "v2URL": "http://app.meupri.com/",
    "serverIp": "https://api.meupri.com/",
    "realtimeServerIp": "https://notifications.mbeta.info/",
    "imagesServer": "https://images.meupri.com/",
    "emailLinksDomain": "links.mbeta.info",
    "emailLinksDomainAndroid": "https://dating.mbeta.info",
    "listsTTL": 600000,
    "payment": {
        "server": "https://test.oppwa.com/v1/",
        "token": "OGE4Mjk0MTg0NzNmMDg5MTAxNDczZjhjYWFlYzAxMzF8M1hSampXUjI=",
        "entityId": "8a829418473f979601473f9befa5000a",
        "currency": "BRL",
        "boletoState": "SP",
        "boletoCity": "São Paulo",
        "boletoStreet": "Av. Paulista, 1636 - cj 706, Bela Vista",
        "boletoCEP": "01310-200",
        "boletoStreetNumber": "1636",
        "boletoComplement": "cj 706",
        "boletoDistrict": "Bela Vista",
        "boletoStreetOnly": "Av. Paulista"
    },
    "paymentBrands": [
        "VISA",
        "MASTER",
        "AMEX",
        "ELO"
    ],
    "gameTrial": {
        "enableDeactivateGame": true,
        "enableMessageGame": false
    },
    "moip": {
        "pubkey": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAo05FNF35ok0001A1Rm0L\nKIXeNbgObAzOLxp7GYI97g+7aZOoafDDZQ2/liihByLhM5iZiDQXSYi9KUy4UBF8\njiJm1+XWVIu1ksReJyXd3XL7kXWiHDr/rXauBedI9S6rEJKoqoUsR76dW40AQy6N\nJJk6R43Ht3rlsBDdvE8L7hBvuDcBNvQXknkRsmy0JVLW+RSXDTOrboKXOAtu8JyC\ndEyJT6zdFSi+b08Dq0IjY56bQ7kdI0IgiESUgwjTm//LQOg9F4xGM9tLW6hRxOJG\n+tHhq+hsKisPn6jtBUOPuOqSygmqGdMvegNbAYb1oUTgj4Bm6VLaoe+yHdJ4p9Gq\nUwIDAQAB\n-----END PUBLIC KEY-----",
        "url": "https://sandbox.moip.com.br",
        "token": "U05ZREdVTElST0lXV1FHQVJLT0g2UTJMREtVU1pFVUU6OEJFRllDWUVQQUFHVEtWVUtTSEFNWFJRUDFVWVFJRTJMMzg3Q0ZMUw==",
        "production": false,
        "shopUrl": "ecmoip*MEUPAT"
    },
    "enablePushv2": true,
    "development": true,
    "showLogs": {
        "downloads": true,
        "device": true,
        "notifications": true,
        "routing": true,
        "redux": true,
        "fireBase": true,
        "location": true,
        "keyboard": true,
        "images": true
    },
    "throttledResponse": 403,
    "GOOGLE_TAG_MANAGER_ID": "GTM-T62CB7P",
    "gpsEnabled": false,
    "translationEnabled": false,
    "defaultPlanDurationIndex": 2,
    "feedbackNagbarPatchMinVersion": 30,
    "isAgeVisibleAvailable": false,
    "isLocationVisibleAvailable": false,
    "isPremiumSettingsAvailable": true,
    "isReferralAvailableOnAndroid": false,
    "isPhotoVerifyAvailableOnAndroid": false,
    "referralFriendshipDayEnabled": false,
    "verifyPhotoModalTimeLapse": 300000,
    "mpUrlContact": "https://www.meuprincipe.com/contato",
    "installmentsPercentage": 30,
    "hideSpanishOption": false,
    "billingAddressInExpressApproval": true,
    "cepIntegrationEndPoint": "https://viacep.com.br",
    "imagesFolderBaseUrl": "/assets/img/",
    "isBoostEnabled": false,
    "isBoostForBabiesEnabled": false,
    "supportLink": "https://principe22stg.wpengine.com/faq/",
    "reduxName": "mp-redux-v28",
    "boostBFFUrl": "https://api.meupri.com/boost-bff"
};