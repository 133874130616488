import 'core-js/proposals/reflect-metadata';
require('zone.js');

import { Config } from '@prince/config';

if (!Config.development) {
    // Production
} else {
    // Development and test
    Error['stackTraceLimit'] = Infinity;
    require('zone.js/dist/long-stack-trace-zone');
}
